export const extractPlainTextFromRichText = (rawValue) => {
    if (!rawValue) return ""

    const traverseNode = (node) => {
        if (node.nodeType === "text") {
            return node.value || ""
        }

        if (node.content && Array.isArray(node.content)) {
            return node.content.map(traverseNode).join(" ")
        }

        return ""
    }

    try {
        const parsedDocument = JSON.parse(rawValue)
        return traverseNode(parsedDocument)
    } catch (error) {
        // console.error("Error parsing Rich Text:", error)
        return ""
    }
}
