const isBrowser = typeof window !== "undefined"

export const bookingToDataLayer = (event, payload) => {
  if (!isBrowser) return

  window.dataLayer = window.dataLayer || []

  window.dataLayer.push({
    event: event,
    ...payload,
  })
}
