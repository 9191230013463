import { createSlice, current } from "@reduxjs/toolkit"

export const bookFlowInitialState = {
  appointmentType: "",
  step: 0,
  fromSpecialistsPage: false,
  checkout: {
    category: null,
    service: null,
    doctor: null,
    selectedDoctor: null,
    location: null,
    timeAndDate: null,
    patientInformation: null,
    comment: null,
    buttonContinue: false,
    selectedSlot: null,
  },
  insurance: null,
  userTryToSubmit: false,
  isLoadingBookingButton: false,
  availabilities: [],
  categoryAvailabilities: [],
  clinissians: [],
  modalThankYouOn: false,
  availabilitiesLoader: false,
  email: "",
  code: "",
  appleAndGooglePay: {
    paymentIntent: null,
    clientSecret: null,
    paymentRequest: null,
  },
  allPageLoader: false,
  specialtiesCategoryData: null,
  specialtiesFilters: [],
  specialists: [],
  filteredSpecialists: [],
}

export const bookFlowSlice = createSlice({
  name: "bookFlow",
  initialState: bookFlowInitialState,
  reducers: {
    setAppointmentType: (state, { payload }) => {
      state.appointmentType = payload
    },
    setStep: (state, { payload }) => {
      state.step = payload
    },
    setServiceCategory: (state, { payload }) => {
      state.serviceCategory = payload
    },
    setSelectedDoctor: (state, { payload }) => {
      state.checkout.selectedDoctor = payload
    },
    setLocation: (state, { payload }) => {
      state.location = payload
    },
    setCheckoutValuesChanges: (state, { payload }) => {
      state.checkout = {
        ...state.checkout,
        [payload.name]: payload.value,
        ...payload.clearFields,
        buttonContinue: payload.button,
      }
    },
    setButtonContinueToggle: (state, { payload }) => {
      state.checkout.buttonContinue = payload
    },
    setEmail: (state, { payload }) => {
      state.email = payload
    },
    setModalToggle: (state, { payload }) => {
      state.modalThankYouOn = payload
    },
    setCommentChange: (state, { payload }) => {
      state.checkout.comment = payload
    },
    resetToInitialState: state => {
      state = Object.assign(state, bookFlowInitialState)
    },
    setFromSpecialistsPage: (state, { payload }) => {
      state.fromSpecialistsPage = payload
    },
    setAvailabilities: (state, { payload }) => {
      state.availabilities = payload
    },
    setCategoryAvailabilities: (state, { payload }) => {
      state.categoryAvailabilities = payload
    },
    setAvailabilitiesLoader: (state, { payload }) => {
      state.availabilitiesLoader = payload
    },
    setClinissians: (state, { payload }) => {
      state.clinissians = payload
    },
    setInsurance: (state, { payload }) => {
      state.insurance = payload
    },
    setUserTryToSubmit: (state, { payload }) => {
      state.userTryToSubmit = payload
    },
    setLoadingButton: (state, { payload }) => {
      state.isLoadingBookingButton = payload
    },
    setAppleAndGooglePay: (state, { payload }) => {
      state.appleAndGooglePay = payload
    },
    setAllPageLoader: (state, { payload }) => {
      state.allPageLoader = payload
    },
    setSpecialtiesCategoryData: (state, { payload }) => {
      state.specialtiesCategoryData = payload
    },
    setBookingSpecialists: (state, { payload }) => {
      state.specialists = payload
      state.filteredSpecialists = payload
    },
    setSpecialtiesFilters: (state, { payload }) => {
      state.specialtiesFilters = payload
      const currentSelectedFilters = [
        ...payload.filter(el => !!el.value).map(el => el.name),
      ]

      if (currentSelectedFilters.length === 0) {
        state.filteredSpecialists = state.specialists
        return
      }
      const filteredSpecialists = current(state.specialists).filter(
        specialist => {
          const haveMatch = specialist.specialties.slice(1).some(el => {
            return currentSelectedFilters.includes(el.title)
          })

          if (haveMatch) {
            return true
          }
          return false
        }
      )

      state.filteredSpecialists = [...filteredSpecialists]
    },
  },
})

export const {
  setAppointmentType,
  setStep,
  setServiceCategory,
  setSelectedDoctor,
  setLocation,
  setButtonContinueToggle,
  setCheckoutValuesChanges,
  setModalToggle,
  setEmail,
  setCommentChange,
  resetToInitialState,
  setFromSpecialistsPage,
  setAvailabilities,
  setCategoryAvailabilities,
  setAvailabilitiesLoader,
  setClinissians,
  setInsurance,
  setUserTryToSubmit,
  setLoadingButton,
  setAppleAndGooglePay,
  setAllPageLoader,
  setSpecialtiesCategoryData,
  setSpecialtiesFilters,
  setBookingSpecialists,
} = bookFlowSlice.actions

export default bookFlowSlice.reducer
